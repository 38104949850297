import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Checkbox, ListItemText } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import { useEffect, useState, useContext } from 'react';
import api from "../../services/api";
import openSocket from "socket.io-client";
import { AuthContext } from "../../context/Auth/AuthContext";

const TicketsQueueSelect = ({
	userQueues,
	selectedQueueIds = [],
	onChange,
  }) => {

	const [data, setData] = useState({});
	const { user: loggedInUser } = useContext(AuthContext);

	const handleChange = (e, child) => {
	  if (child.props.value === 'all') {
		handleSelectAll(e);
	  } else {
		onChange(e.target.value);
	  }
	};
  
	const handleSelectAll = e => {
		if (selectedQueueIds.length === userQueues.length) {
			onChange([]);
		} else {
			onChange(userQueues.map(queue => queue.id));
		}
	};
		

	useEffect(() => {

		const ticketQueue = async () => {

			const socket = openSocket(process.env.REACT_APP_BACKEND_URL);
			var response = null
			socket.on("connect", () => socket.emit("joinUserRoom", loggedInUser.id));

			if(loggedInUser.profile == 'admin'){
				response = await api.get(`/allUsersPerTicket?userid=0`);
			}else{	
				response = await api.get(`/allUsersPerTicket?userid=${loggedInUser.id}`);
			}
			
			let queuesWhitTickets = response.data;
	
			for (let index = 0; index < queuesWhitTickets.length; index++) {	
				setData((prevData) => ({ ...prevData, [queuesWhitTickets[index].queueId]: queuesWhitTickets[index].total }));
			}

			socket.on("ticketQueue", async (data) => {
				if(data.action == "update"){

					let response = null

					if(data.userAdmin){
						response = await api.get(`/allUsersPerTicket?userid=0`);
					}else{
						response = await api.get(`/allUsersPerTicket?userid=${data.contactId}`);
					}
					
					let queuesWhitTickets = response.data;

					userQueues.forEach(queue => {	
						setData((prevData) => ({ ...prevData, [queue.id]: null }));	
					});
				
					for (let index = 0; index < queuesWhitTickets.length; index++) {
						setData((prevData) => ({ ...prevData, [queuesWhitTickets[index].queueId]: queuesWhitTickets[index].total }));	
					}
				}
			});

			return () => {
				socket.disconnect();
			};

		};
	
		ticketQueue();

	}, []);

	return (
	  <div style={{ width: 120, marginTop: -4 }}>
		<FormControl fullWidth margin="dense">
		  <Select
			multiple
			displayEmpty
			variant="outlined"
			value={selectedQueueIds}
			onChange={handleChange}
			MenuProps={{
			  anchorOrigin: {
				vertical: "bottom",
				horizontal: "left",
			  },
			  transformOrigin: {
				vertical: "top",
				horizontal: "left",
			  },
			  getContentAnchorEl: null,
			}}
			renderValue={() => i18n.t("ticketsQueueSelect.placeholder")}
		  >
			<MenuItem dense value='all'>
			  <Checkbox
				size="small"
				style={{color: 'blue'}}
				checked={selectedQueueIds.length === userQueues.length}
			  />
			  <ListItemText primary="Selecionar todos" />
			</MenuItem>
			{userQueues?.length > 0 &&
			  userQueues.map(queue => (
				<MenuItem dense key={queue.id} value={queue.id}>
				  <Checkbox
					style={{
					  color: queue.color,
					}}
					size="small"
					color="primary"
					checked={selectedQueueIds.indexOf(queue.id) > -1}
				  />
				  <ListItemText primary={queue.name} />
					<p> {data[queue.id]} </p>
				</MenuItem>
			  ))}
		  </Select>
		</FormControl>
	  </div>
	);
  };
  
  export default TicketsQueueSelect;
  

