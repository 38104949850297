import React, { useContext, useEffect, useState } from "react"
import Paper from "@material-ui/core/Paper"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from "@material-ui/core/Typography";
import useTickets from "../../hooks/useTickets"
import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import Chart from "./Chart"
import api from "../../services/api";
import { toast } from "react-toastify";

/* Importa��es exclusivas da tabela */

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(8, 8, 3),
		backgroundColor: theme.palette.background.default
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	fixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 240,
	},
	customFixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 120,
	},
	customFixedHeightPaperLg: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: "100%",
	},
}))

const columns = [
	{ id: 'atendente', label: 'Atendente', minWidth: 170 },
	{
		id: 'servico',
		label: i18n.t("dashboard.messages.inAttendance.title"),
		minWidth: 170,
		align: 'right',
		format: (value) => value.toLocaleString('en-US'),
	},
	{
		id: 'esperando',
		label: i18n.t("dashboard.messages.waiting.title"),
		minWidth: 170,
		align: 'right',
		format: (value) => value.toLocaleString('en-US'),
	},
	{
		id: 'fechado',
		label: i18n.t("dashboard.messages.closed.title"),
		minWidth: 170,
		align: 'right',
		format: (value) => value.toFixed(2),
	},
];

const Dashboard = () => {
	const classes = useStyles()
	const { user } = useContext(AuthContext);
	const [page, setPage] = React.useState(0);
	const [rows, setRows] = React.useState([]);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [dateInicial, setDateInicial] = useState('');
  	const [dateFinal, setDateFinal] = useState('');
	var userQueueIds = [];

	useEffect(() => {
		fetchData();
	}, []);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const fetchData = async () => {

		let valores = []
		let nomeAtendente = ''
		let totalFechado = 0
		let totalPendente = 0
		let totalAberto = 0

		try {	

			if(dateInicial && dateFinal){
				
				let startDate = `${dateInicial} 00:00:00`;
				let endDate   = `${dateFinal} 23:59:59`;

				var { data } = await api.get(`/allTicketsStatePerUsers?startDate=${startDate}&endDate=${endDate}`);
			}else{
				var { data } = await api.get(`/allTicketsStatePerUsers?startDate=${null}&endDate=${null}`);
			}

			for(let i = 0; i < data.length; i++){
				nomeAtendente = data[i].name;
				while (i < data.length && data[i].name == nomeAtendente) {
					if(data[i].status == 'closed'){
						totalFechado  = data[i].ticketCount;
					}else if(data[i].status == 'pending'){
						totalPendente = data[i].ticketCount;
					}else if(data[i].status == 'open'){
						totalAberto   = data[i].ticketCount;
					}
					i++;
				}
			
				valores.push([nomeAtendente, totalAberto, totalPendente, totalFechado]);
			
				totalAberto = 0;
				totalPendente = 0;
				totalFechado = 0;
				i--;
			}	

			setRows(valores);
			toast.success(i18n.t("dashboard.usersPerTickets.return.title"));
		} catch (error) {
			console.error('Erro ao buscar dados:', error);
		}
	};

	if (user.queues && user.queues.length > 0) {
		userQueueIds = user.queues.map(q => q.id);
	}

	const GetTickets = (status, showAll, withUnreadMessages) => {

		const { tickets } = useTickets({
			status: status,
			showAll: showAll,
			withUnreadMessages: withUnreadMessages,
			queueIds: JSON.stringify(userQueueIds)
		});
		return tickets.length;
	}

	return (
		<div className={classes.root}>
			<Container maxWidth="lg" className={classes.container}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Grid item xs={12} style={{marginBottom: '5vh' }}>
							<TextField
								id="date inicial"
								label="Data Inicial"
								type="date"
								InputLabelProps={{
									shrink: true,
								}}
								style={{ 
									marginRight: '10vh', 
									color: 'blue',
									width: '20%',
								}}
								value={dateInicial}
								onChange={e => setDateInicial(e.target.value)}
								focused
							/>
							<TextField
								id="date final"
								label="Data Final"
								type="date"
								InputLabelProps={{
									shrink: true,
								}}
								style={{ 
									marginRight: '10vh', 
									color: 'blue',
									width: '20%',
								}}
								value={dateFinal}
								onChange={e => setDateFinal(e.target.value)}
								focused
							/>
							<Button 
								variant="contained"
								size="large"
								style={{
									backgroundColor: '#2576d2',
									height: '56px',
									width: '130px'
								}}
								onClick={() => fetchData(dateInicial, dateFinal)}
							>
								Pesquisar
							</Button>
						</Grid>
						<TableContainer sx={{ maxHeight: 440 }}>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										{columns.map((column) => (
											<TableCell
												key={column.id}
												align={column.align}
												style={{ minWidth: column.minWidth, backgroundColor: '#2576d2', color: 'white'}}
												>
												{column.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{rows
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row, index) => {
										return (
										<TableRow hover 
										role="checkbox" 
										tabIndex={-1} 
										key={index}
										sx={{ backgroundColor: index % 2 === 0 ? 'lightgrey' : 'white' }}
										>{columns.map((column) => {
											let value = null
											if(column.id == "atendente"){
												value = row[0];
											}else if(column.id == "servico"){
												value = row[1];
											}else if(column.id == "esperando"){
												value = row[2];
											}else if(column.id == "fechado"){
												value = row[3];
											}
											return (
												<TableCell key={column.id} align={column.align}>
												{value}
												</TableCell>
											);
											})}
										</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[10, 25, 100]}
							component="div"
							count={rows.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</Grid>
					<Grid item xs={4}>
						<Paper className={classes.customFixedHeightPaper} style={{ overflow: "hidden" }}>
							<Typography component="h3" variant="h6" color="primary" paragraph>
								{i18n.t("dashboard.messages.inAttendance.title")}
							</Typography>
							<Grid item>
								<Typography component="h1" variant="h4">
									{GetTickets("open", "true", "false")}
								</Typography>
							</Grid>
						</Paper>
					</Grid>
					<Grid item xs={4}>
						<Paper className={classes.customFixedHeightPaper} style={{ overflow: "hidden" }}>
							<Typography component="h3" variant="h6" color="primary" paragraph>
								{i18n.t("dashboard.messages.waiting.title")}
							</Typography>
							<Grid item>
								<Typography component="h1" variant="h4">
									{GetTickets("pending", "true", "false")}
								</Typography>
							</Grid>
						</Paper>
					</Grid>
					<Grid item xs={4}>
						<Paper className={classes.customFixedHeightPaper} style={{ overflow: "hidden" }}>
							<Typography component="h3" variant="h6" color="primary" paragraph>
								{i18n.t("dashboard.messages.closed.title")}
							</Typography>
							<Grid item>
								<Typography component="h1" variant="h4">
									{GetTickets("closed", "true", "false")}
								</Typography>
							</Grid>
						</Paper>
					</Grid>
					<Grid item xs={12}>
						<Paper className={classes.fixedHeightPaper}>
							<Chart />
						</Paper>
					</Grid>
				</Grid>
			</Container>
		</div>
	)
}

export default Dashboard
