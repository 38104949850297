import { useState, useEffect, useContext } from "react";

import toastError from "../../errors/toastError";

import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";

const useTickets = ({
	searchParam,
	pageNumber,
	status,
	date,
	showAll,
	queueIds,
	withUnreadMessages,
    checkPhone,
}) => {
	const [loading, setLoading] = useState(true);
	const [hasMore, setHasMore] = useState(false);
	const [tickets, setTickets] = useState([]);
	const [count, setCount] = useState(0);
    const { user } = useContext(AuthContext);

	useEffect(() => {
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchTickets = async(searchParam) => {
				try {
					const { data } = await api.get("/tickets", {
						params: {
							searchParam,
							pageNumber,
							status,
							date,
							showAll,
							queueIds,
							withUnreadMessages,
                            checkPhone,
						},
					});

					setTickets(data.tickets);

					let horasFecharAutomaticamente = process.env.REACT_APP_HOURS_CLOSE_TICKETS_AUTO; 

                    if (status === "open" && horasFecharAutomaticamente && horasFecharAutomaticamente !== "" &&
                        horasFecharAutomaticamente !== "0" && Number(horasFecharAutomaticamente) > 0) {

                        let dataLimite = new Date()
                        dataLimite.setHours(dataLimite.getHours() - Number(horasFecharAutomaticamente))

                        data.tickets.forEach(ticket => {
                            if (ticket.status !== "closed") {
                                let dataUltimaInteracaoChamado = new Date(ticket.updatedAt)
                                if (dataUltimaInteracaoChamado < dataLimite)
                                    closeTicket(ticket)
                            }
                        })
                    }

                    setHasMore(data.hasMore)
                    setCount(data.count)
                    setLoading(false)
                    loadingNotifications(user?.id);
                } catch (err) {
                    setLoading(false)
                    toastError(err)
                }
            }

            const closeTicket = async(ticket) => {
                await api.put(`/tickets/${ticket.id}`, {
                    status: "closed",
                    userId: ticket.userId || null,
                })
            }

            const loadingNotifications = async (userid) => {
                const { data } = await api.get(`/ticketsPerUser?userid=${userid}`)
            
                if (data.length > 0) {
                    document.title = `(${data.length}) ${process.env.REACT_APP_TITLE}`;
                } else {
                    document.title = `${process.env.REACT_APP_TITLE}`;
                }
            }

            fetchTickets(searchParam);

        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [
        searchParam,
        pageNumber,
        status,
        date,
        showAll,
        queueIds,
        withUnreadMessages,
    ])

    return { tickets, loading, hasMore, count };
};

export default useTickets;